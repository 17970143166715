import { app as msTeamsApp } from '@microsoft/teams-js';
import { useEffect, useMemo, useState } from 'react';

import { useQueryLocalApp } from 'gql/queries/local';
import { useQueryMe } from 'gql/queries/me';

const MINIMUM_SMALL_SIDE_PANEL_WIDTH = 930;
const MINIMUM_LARGE_SIDE_PANEL_WIDTH = 1130;

const useSiteContext = () => {
  const [expoPushToken] = useState(() => {
    try {
      return window.expoPushToken;
    } catch (e) {
      return null;
    }
  });

  const [isInFrame] = useState(() => {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  });

  const [isInNative] = useState(() => {
    try {
      return !!window.isInNative;
    } catch (e) {
      return false;
    }
  });

  const [isOnMobile] = useState(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream; // !window.MSStream is to not incorrectly detect IE11
    const isAndroid = /android/i.test(userAgent);
    const isWindows = /windows phone/i.test(userAgent);
    return isIOS || isAndroid || isWindows || isInNative;
  }, []);

  const [browser] = useState(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream; // !window.MSStream is to not incorrectly detect IE11
    const isAndroid = /android/i.test(userAgent);
    const isWindows = /windows phone/i.test(userAgent);
    const isInStandaloneMode =
      'standalone' in window.navigator && window.navigator.standalone;
    return isIOS && !isInStandaloneMode && !isInNative
      ? 'iOS'
      : (isAndroid || isWindows) && !isInStandaloneMode && !isInNative
      ? 'Android'
      : undefined;
  }, []);

  const [isInTeams, setInTeams] = useState(false);

  useEffect(() => {
    (async function initializeTeams() {
      try {
        await msTeamsApp.initialize();
        setInTeams(true);
      } catch (e) {
        //
      }
    })();
  }, []);

  const {
    data: {
      app: { isAuthenticated, isMenuExpanded },
    },
  } = useQueryLocalApp();

  const { data, isLoading } = useQueryMe({
    skip: !isAuthenticated,
  });

  const isGAR =
    !isAuthenticated || isLoading ? false : data.me.tags.includes('gar');
  const isTNECanope =
    !isAuthenticated || isLoading ? false : data.me.tags.includes('tne-canope');
  const isRoleTeacher =
    !isAuthenticated || isLoading ? false : data.me.role === 'teacher';
  const isRoleStudent =
    !isAuthenticated || isLoading ? false : data.me.role === 'student';
  const isEditionDisabled =
    !isAuthenticated || isLoading
      ? false
      : data.me.organization?.settings?.isEditionDisabled;

  const isNewUIFlagged =
    !isAuthenticated || isLoading
      ? false
      : data.me.flags?.includes('question_edition_new_ui');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const isNewQuestionEditorUIDisplayed = useMemo(() => {
    const minimumSidePanelWidth = isMenuExpanded
      ? MINIMUM_LARGE_SIDE_PANEL_WIDTH
      : MINIMUM_SMALL_SIDE_PANEL_WIDTH;
    return isNewUIFlagged && windowWidth > minimumSidePanelWidth;
  }, [isMenuExpanded, isNewUIFlagged, windowWidth]);

  return {
    browser,
    expoPushToken,
    isEditionDisabled,
    isInFrame,
    isInNative,
    isInTeams,
    isExternal: isInFrame || isInTeams,
    isGAR,
    isNewQuestionEditorUIDisplayed,
    isOnMobile,
    isTNECanope,
    isRoleTeacher,
    isRoleStudent,
  };
};

export default useSiteContext;
